import React, { createRef, useMemo, useState } from "react";
import { graphql, Link } from "gatsby";
import algoliasearch from "algoliasearch/lite";
import { GatsbyImage } from "gatsby-plugin-image";
import { InstantSearch } from "react-instantsearch-dom";
import Layout from "@components/layout";
import Seo from "@components/seo";
import Footer from "@components/FooterSection";
import useDebounce from "@utils/debounce";
import Breadcrumb from "@components/Breadcrumb";
import { navigate } from "gatsby-link";
import { DeepNonNullable } from "ts-essentials";
import { AllCasesDataQuery, AllPostsDataQuery } from "../../graphqlTypes";
import styled from "styled-components";
import { TAILWIND_DEFAULTS } from "@utils/GlobalConstants";
import Pagination, { BlogContext } from "@ui/Pagination";
import useOnClickOutside from "@utils/hooks/useOnClickOutside";
import SearchBoxAlgolia from "@components/SearchBoxAlgolia";
import SearchResult from "@components/SearchResultsAlgolia";
import NewsletterBanner from "@ui/NewsletterBanner";

export const BlogPosts = graphql`
  query AllCasesData($skip: Int!, $limit: Int!) {
    strapiCases {
      pageTitle
      title
      subTitle
      readMoreButtonText
    }
    allStrapiCustomerCases(limit: $limit, skip: $skip) {
      edges {
        node {
          companyName
          companyTeamPhoto {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              id
              extension
              publicURL
            }
            alternativeText
          }
          companyLogo {
            id
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 150, layout: FIXED)
              }
              extension
              publicURL
            }
          }
          title
          excerpt1
          excerpt2
          slug
          companyPersonName
          companyPersonTitle
        }
      }
    }
  }
`;

const ArrowRight = styled.div`
  /* margin-top: 10%; */
  background-color: rgb(229, 231, 235);
  transform: rotate(-45deg);
  border-radius: 10%;
  left: calc(100% + -25px);
  display: inline-table;
  width: 30px;
  height: 30px;
  position: relative;
  top: -80%;
  z-index: 0;
  /* As the md breakpoint in tailwind */
  @media (max-width: ${TAILWIND_DEFAULTS.screens.md}) {
    display: none;
  }
`;

const SVGLines = () => (
  <div
    className="absolute h-full"
    style={{
      top: "-40px",
      left: "20px",
      zIndex: -1,
    }}
  >
    <svg width="48" height="6">
      <circle
        cx="45"
        cy="3"
        r="2"
        fill="white"
        stroke="#C7DB4E"
        stroke-width="2"
      />
      <circle
        cx="31"
        cy="3"
        r="2"
        fill="white"
        stroke="#82CA69"
        stroke-width="2"
        vector-effect="non-scaling-stroke"
      />
      <circle
        cx="17"
        cy="3"
        r="2"
        fill="white"
        stroke="#38E2A5"
        stroke-width="2"
        vector-effect="non-scaling-stroke"
      />
      <circle
        cx="3"
        cy="3"
        r="2"
        fill="white"
        stroke="#38B9E2"
        stroke-width="2"
        vector-effect="non-scaling-stroke"
      />
    </svg>
    <svg
      width="48"
      height="100%"
      viewBox="0 0 48 36"
      fill="none"
      preserveAspectRatio="none"
      style={{
        marginTop: "-2px",
      }}
    >
      <rect
        x="47"
        y="36"
        height="100%"
        width="4.00001"
        transform="rotate(-180 47 36)"
        fill="#C7DB4E"
      />
      <rect
        x="33"
        y="36"
        width="4.00001"
        height="100%"
        transform="rotate(-180 33 36)"
        fill="#82CA69"
      />
      <rect
        x="19"
        y="36"
        width="4.00001"
        height="100%"
        transform="rotate(-180 19 36)"
        fill="#38E2A5"
      />
      <rect
        x="5"
        y="36"
        width="4.00001"
        height="100%"
        transform="rotate(-180 5 36)"
        fill="#38B9E2"
      />
    </svg>
  </div>
);

const CasesPage = ({
  data,
  pageContext,
}: {
  data: DeepNonNullable<AllCasesDataQuery>;
  pageContext: BlogContext;
}) => {
  const { edges } = data.allStrapiCustomerCases;
  const { pageTitle, title, subTitle, readMoreButtonText } = data.strapiCases;

  return (
    <Layout>
      <Seo title={pageTitle} />
      <div className="div-block flex flex-col max-w-7xl mx-5 md:mx-auto px-4 sm:px-0 mt-4 mb-4">
        <Breadcrumb title="Cases" />
        <div className="grid grid-cols-5 gap-4">
          <div className="col-start-1 col-end-6 md:col-end-5">
            <p className="text-4xl text-white font-semibold mt-16 mb-6">
              {title}
            </p>
            <p className="text-gray-300 font-light">{subTitle}</p>
          </div>
        </div>
      </div>
      <div className="full-width-strip bg-accuRankerCream">
        <div className="div-block max-w-fit md:max-w-7xl bg-accuRankerCream w-full pb-12 mx-5 md:m-auto px-0 sm:px-0">
          <div className="max-w-7xl flex flex-col gap-11 m-auto sm:px-0">
            <div>
              <div className="relative z-10">
                <SVGLines />
                <div className="w-full flex flex-col gap-8 pt-10">
                  {edges.map(edge => {
                    const {
                      node: {
                        companyTeamPhoto: {
                          localFile: {
                            childImageSharp: { gatsbyImageData: postImage },
                          },
                          alternativeText,
                        },
                        companyLogo,
                        slug,
                        title,
                        excerpt1,
                        excerpt2,
                        companyPersonName,
                        companyPersonTitle,
                      },
                    } = edge;
                    return (
                      <Link to={`/cases/${slug}/`}>
                        <div className="flex flex-row rounded-lg cursor-pointer bg-gray-200 max-h-80 border-2 shadow-lg">
                          {postImage && (
                            <div
                              className="bg-gray-100 relative rounded-lg"
                              style={{ width: "400px", zIndex: 0 }}
                            >
                              <GatsbyImage
                                alt={alternativeText}
                                objectFit="cover"
                                imgClassName="rounded-lg"
                                className="rounded-lg h-full"
                                image={postImage}
                                style={{ width: "400px" }}
                              />
                              <ArrowRight />
                            </div>
                          )}
                          <div
                            className="flex flex-col p-6 rounded-r-lg gap-2 bg-gray-100"
                            style={{
                              marginLeft: "-14px",
                              zIndex: 10,
                            }}
                          >
                            <div className="mb-6">
                              {!companyLogo.localFile.childImageSharp &&
                              companyLogo.localFile.extension === "svg" ? (
                                <img
                                  src={companyLogo.localFile.publicURL}
                                  alt={companyLogo.alternativeText}
                                />
                              ) : (
                                <GatsbyImage
                                  key={companyLogo.id}
                                  image={
                                    companyLogo.localFile.childImageSharp
                                      .gatsbyImageData
                                  }
                                  alt={companyLogo.alternativeText}
                                />
                              )}
                            </div>
                            <p className="text-2xl mb-1">{title}</p>
                            <span className="text-gray-500">“{excerpt2}”</span>
                            <p>
                              {companyPersonName}, {companyPersonTitle}
                            </p>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
              <div className="my-6">
                <Pagination {...pageContext} />
              </div>
              {/*<NewsletterBanner*/}
              {/*  onClick={() => {*/}
              {/*    alert("implement me");*/}
              {/*  }}*/}
              {/*/>*/}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default CasesPage;
