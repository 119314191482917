/** Header to Off-sidebar */
export const HEADER_OFF_SIDEBAR = 200; // px

/** To use them in JSS */
export const TAILWIND_DEFAULTS = {
  screens: {
    sm: "640px",
    // => @media (min-width: 640px) { ... }

    md: "768px",
    // => @media (min-width: 768px) { ... }

    lg: "1024px",
    // => @media (min-width: 1024px) { ... }

    xl: "1280px",
    // => @media (min-width: 1280px) { ... }

    "2xl": "1536px",
    // => @media (min-width: 1536px) { ... }
  },
};
