import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import pagination from "@utils/pagination";
import { navigate } from "gatsby-link";
import classNames from "classnames";
import { Link } from "gatsby";

/** Mirror the context provided to the the Blog front page */
export type BlogContext = {
  limit: number;
  skip: number;
  numPages: number;
  currentPage: number;
  path: string;
};

const ButtonClasses =
  "flex flex-col cursor-pointer justify-center hover:bg-accuRankerOrange-500 hover:text-white rounded-full w-6 h-6 text-center";

export const Pagination = (props: BlogContext) => {
  const { currentPage, numPages, path = "blog" } = props;

  const isFirst = currentPage === 0;
  const isLast = currentPage === numPages - 1;

  return (
    <div className="flex flex-row gap-6 text-base font-medium items-center justify-center">
      {!isFirst && (
        <Link
          to={`/${path}/` + (currentPage === 1 ? "" : `${currentPage}/`)}
          className={ButtonClasses}
          rel={"prev"}
        >
          <ChevronLeftIcon className="h-4" />
        </Link>
      )}
      {pagination(currentPage, numPages).map(page => (
        <Link
          to={`/${path}/` + (page === 1 ? "" : `${page}/`)}
          className={classNames({
            [`${ButtonClasses}`]: typeof page === "number",
            "bg-accuRankerOrange-500 text-white":
              typeof page === "number" && currentPage === page - 1,
          })}
        >
          <span>{page}</span>
        </Link>
      ))}
      {!isLast && (
        <Link
          to={`/${path}/${currentPage + 2}/`}
          className={ButtonClasses}
          rel={"next"}
        >
          <ChevronRightIcon className="h-4" />
        </Link>
      )}
    </div>
  );
};

export default Pagination;
